
  import { prismicLink } from '@/utils/url'

  export default {
    name: 'CallToAction',
    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        },
      },
    },
    computed: {
      link() {
        return prismicLink(
          this.slice.primary.link,
          this.$prismic.asText(this.slice.primary.linkLabel)
        )
      },
      background() {
        return {
          src: {
            url: this.slice.primary.background.url,
            alt: this.slice.primary.background.alt,
          },
          width: this.slice.primary.background.dimensions.width,
          height: this.slice.primary.background.dimensions.height,
        }
      },
    },
    methods: {
      scrollToNext() {
        var scrollTo = document.getElementById('scroll-request-whitepaper')
        scrollTo.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      },
    },
  }
